<script>
// import swal from 'sweetalert2';
import VCards from '@/components/Cards.vue';

export default {
  components: {
    VCards,
  },
  data() {
    return {
      isUnlockingCards: false
    };
  },
  mounted() {
    this.unlockCards();
  },
  methods: {
    getCardToUnlock() {
      return localStorage.getItem('cardToUnlock');
    },
    isOneCardToUnlock() {
      return localStorage.getItem('isOneCard');
    },
    unlockCards() {
      if (localStorage.getItem('cardToUnlock') !== null) {
        if ((localStorage.getItem('isOneCard') === 'true')) {
          this.$http.post(`/cards/unlock-by-four-last-numbers/${localStorage.getItem('cardToUnlock')}`)
            .then(({ data }) => {
              console.log('RESPONSE FROM UNLOCK: ', data);
              localStorage.removeItem('cardToUnlock');
              window.location.reload();
              this.$store.commit('pageLoading', false);
            })
            .catch(() => {
              localStorage.removeItem('cardToUnlock');
              window.location.reload();
              this.$store.commit('pageLoading', false);
            });
        } else {
          this.$http.post('/cards/unlock-all')
            .then(({ data }) => {
              console.log('RESPONSE FROM UNLOCK: ', data);
              localStorage.removeItem('cardToUnlock');
              window.location.reload();
              this.$store.commit('pageLoading', false);
            })
            .catch(() => {
              localStorage.removeItem('cardToUnlock');
              window.location.reload();
              this.$store.commit('pageLoading', false);
            });
        }
      }
    },
    removeAllcardsToUnlock() {
      localStorage.removeItem('cardToUnlock');
    }
  }
};
</script>

<template>
  <div class="cards">
    <v-cards v-if="isUnlockingCards === false" :showBalance="true" :showCanceledCard="true"/>
  </div>
</template>

<style lang="postcss" scoped>
  .cards {
    @media screen and (max-width: 991px) {
      /* padding-top: 5vh; */
    }
  }
</style>
