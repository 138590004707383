var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cards" },
    [
      _vm.isUnlockingCards === false
        ? _c("v-cards", {
            attrs: { showBalance: true, showCanceledCard: true },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }